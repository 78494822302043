import { ListItem, ListItemText, List, Chip, Box } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import React, { useState } from "react";

const API = "https://dictionaries-api.vivy.app/glossaries";

const UseageList = ({ currentGlossary = {}, search }) => {
  const [offset, setOffset] = useState(currentGlossary.docs.length || 0);
  const [isLoading, setIsLoading] = useState(false);
  const [docs, setDocs] = useState(currentGlossary.docs || []);
  const [stop, setStop] = useState(false);

  const getMore = async () => {
    setIsLoading(true);

    const data = await fetch(
      `${API}?q=${search.toLocaleLowerCase()}&limit=12&offset=${offset}&origin=${
        currentGlossary.origin
      }`
    ).then((d) => d.json());

    if (data?.error) {
      return setIsLoading(false);
    }
    if (!data.docs.length) {
      setIsLoading(false);
      return setStop(true);
    }

    setDocs((prev) => [...prev, ...data.docs]);
    setOffset(offset + 12);
    setIsLoading(false);
  };

  return (
    <List dence>
      {docs.map(({ source, content, meta }) => {
        return (
          <ListItem
            button
            sx={{
              borderRadius: 1,
              alignItems: "center",
              paddingTop: "6px!important",
            }}
          >
            <ListItemText
              primary={
                <>
                  {source}{" "}
                  {currentGlossary.origin === "anibel.net" &&
                    meta.map(({ type }) => (
                      <Chip
                        label={type}
                        size="small"
                        sx={{ ml: 0.5 }}
                        color="primary"
                      />
                    ))}
                </>
              }
              secondary={
                <div
                  dangerouslySetInnerHTML={{ __html: content }}
                  id="content"
                />
              }
              primaryTypographyProps={{
                variant: "h5",
              }}
              secondaryTypographyProps={{
                variant: "caption",
                sx: {
                  textTransform: "uppercase",
                  mt: 0.5,
                },
              }}
            />
          </ListItem>
        );
      })}
      {!stop && currentGlossary.origin === "anibel.net" && (
        <Box sx={{ display: "flex", justifyContent: "center", mt: 2, mb: 2 }}>
          <LoadingButton
            loading={isLoading}
            onClick={getMore}
            variant="outlined"
          >
            Больш вынікаў
          </LoadingButton>
        </Box>
      )}
    </List>
  );
};

export default UseageList;
