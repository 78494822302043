import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Switch,
} from "@mui/material";
import {
  KeyboardOutlined,
  LanguageOutlined,
  SearchOutlined,
  SettingsOutlined,
} from "@mui/icons-material";
import { useSettings } from "./context/settings";

const defaultSettings = {
  keyboardHelpers: true,
  autoSearch: true,
  language: "be",
};

function Settings({ closeOnOpen }) {
  const defaultState = JSON.parse(
    localStorage.getItem("settings") || JSON.stringify(defaultSettings)
  );

  const { change: setState, ...state } = useSettings();

  const handleChange = (key) => (_, value) => {
    const nextState = {
      ...state,
      [key]: value,
    };

    setState(nextState);
  };
  const isChanged = JSON.stringify(state) !== JSON.stringify(defaultState);
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);

    if (closeOnOpen) {
      closeOnOpen();
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <MenuItem onClick={handleClickOpen} dence>
        <ListItemIcon>
          <SettingsOutlined />
        </ListItemIcon>
        <ListItemText>Налады</ListItemText>
      </MenuItem>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Налады</DialogTitle>
        <DialogContent>
          <List sx={{ m: -1 }}>
            <ListItem>
              <ListItemIcon sx={{ minWidth: 46 }}>
                <KeyboardOutlined />
              </ListItemIcon>
              <ListItemText
                primary="Дапаможнік"
                secondary={
                  <>
                    Паказваць <b>і/ў</b> у поле пошуку. Карысна, калі няма
                    беларускай раскладкі ў сістэме.
                  </>
                }
              />
              <Switch
                edge="end"
                checked={state.keyboardHelpers}
                onChange={handleChange("keyboardHelpers")}
                sx={{ ml: 1 }}
              />
            </ListItem>
            <ListItem>
              <ListItemIcon sx={{ minWidth: 46 }}>
                <SearchOutlined />
              </ListItemIcon>
              <ListItemText
                primary="Аўтапошук"
                secondary={
                  <>
                    Шукаць словы па меры ўводу тэкста ў поле пошуку. Калі
                    выключына пошук ажыццяўляецца праз <b>Enter</b> або
                    націсканне на іконку Лупы.
                  </>
                }
              />
              <Switch
                edge="end"
                sx={{ ml: 1 }}
                checked={state.autoSearch}
                onChange={handleChange("autoSearch")}
              />
            </ListItem>

            <ListItem>
              <ListItemIcon sx={{ minWidth: 46 }}>
                <LanguageOutlined />
              </ListItemIcon>
              <ListItemText
                primary="Мова інтэрфейсу"
                secondary={<>беларуская</>}
              />
            </ListItem>
          </List>
        </DialogContent>
        <DialogActions sx={{ p: 2 }}>
          <Button
            onClick={() => {
              setTimeout(() => setState(defaultState), 250);
              handleClose();
            }}
          >
            Закрыць
          </Button>
          <Button
            onClick={() => {
              localStorage.setItem("settings", JSON.stringify(state));
              handleClose();
            }}
            autoFocus
            variant="contained"
            disabled={!isChanged}
          >
            Захаваць
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default Settings;
