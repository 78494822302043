import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { ListItemIcon, ListItemText, MenuItem } from "@mui/material";
import { InfoOutlined } from "@mui/icons-material";

function About({ closeOnOpen }) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    if (closeOnOpen) {
      closeOnOpen();
    }

    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <MenuItem onClick={handleClickOpen} dence>
        <ListItemIcon>
          <InfoOutlined />
        </ListItemIcon>
        <ListItemText>Аб праграме</ListItemText>
      </MenuItem>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Аб праграме</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            База разнастайных аўтарытэтных гласарыяў беларускай мовы.
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default About;
