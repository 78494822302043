import React, { useCallback, useEffect, useRef, useState } from "react";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import {
  Button,
  CircularProgress,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { SearchOutlined } from "@mui/icons-material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CloseIcon from "@mui/icons-material/Close";
import SentimentDissatisfiedIcon from "@mui/icons-material/SentimentDissatisfied";
import { useWindowSize } from "./hooks/useWindowSize";
import { useTheme } from "@mui/system";
import { AppMenu } from "./AppMenu";
import GlossariesList from "./GlossariesList";
import { useSettings } from "./context/settings";
import { useLocation } from "react-router-dom";
import qs from "qs";
import UseageList from "./UseageList";

const API_URL = "https://dictionaries-api.vivy.app/glossaries";

export default function App() {
  const { width } = useWindowSize();
  const location = useLocation();
  const params = qs.parse(location.search.substring(1));

  const settings = useSettings();
  const theme = useTheme();
  const inputRef = useRef();
  const [search, setSearch] = useState(params?.q || "");
  const [isLoading, setLoading] = useState(false);
  const [result, setResult] = useState();
  const [currentGlossary, setCurrentGlossary] = useState(null);

  const handleChange = useCallback(
    async (e) => {
      const {
        target: { value },
      } = e;

      setSearch(value);
      if (result) {
        setResult(null);
      }

      if (currentGlossary) {
        setCurrentGlossary(null);
      }
    },
    [result, currentGlossary]
  );

  const handleSearch = useCallback(async () => {
    if (search) {
      setLoading(true);

      const data = await fetch(
        `${API_URL}?q=${search.toLocaleLowerCase()}&limit=5`
      ).then((d) => d.json());
      if (data?.error) {
        return setLoading(false);
      }
      setResult(() => data);
      setLoading(false);
    }
  }, [search]);

  useEffect(() => {
    if (settings?.autoSearch === false) {
      return;
    }

    const delayDebounceFn = setTimeout(handleSearch, 250);

    return () => clearTimeout(delayDebounceFn);
  }, [handleSearch, settings]);

  const changeStyles = width > 650;

  return (
    <Container maxWidth="sm" sx={{ position: "relative" }}>
      {params?.embed !== "1" && (
        <>
          <Box
            sx={{
              position: "absolute",
              right: !changeStyles ? 8 : 16,
              top: !changeStyles ? 8 : 16,
            }}
          >
            <AppMenu />
          </Box>
        </>
      )}
      <Box
        sx={{
          justifyContent: "flex-start",
          alignItems: "center",
          display: "flex",
          height: "100vh",
          flexDirection: "column",
          pt: 4,
        }}
      >
        {params?.embed !== "1" && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              pt: 4,
              mb: !changeStyles ? 4 : 0,
            }}
          >
            <img src="/logo.svg" alt="logo" width={!changeStyles ? 40 : 80} />
            <Typography variant="h4" sx={{ ml: 2 }}>
              Гласарыі
            </Typography>
          </Box>
        )}
        {changeStyles && (
          <Typography variant="body1" sx={{ my: 4, textAlign: "center" }}>
            База разнастайных аўтарытэтных гласарыяў беларускай мовы.
          </Typography>
        )}

        <TextField
          placeholder="Пошук ужывання слоў"
          fullWidth
          value={search}
          autoCapitalize="none"
          autoCorrect="none"
          autoSave="none"
          autoFocus
          autoComplete="new-password"
          onChange={handleChange}
          onKeyDown={(e) => {
            if (e.keyCode === 13) {
              handleSearch();
            }
          }}
          inputRef={inputRef}
          InputProps={{
            sx: { borderRadius: 50 },
            startAdornment: (
              <InputAdornment position="start">
                <IconButton onClick={!isLoading ? handleSearch : undefined}>
                  {isLoading ? (
                    <CircularProgress size={24} />
                  ) : (
                    <SearchOutlined />
                  )}
                </IconButton>
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                {settings?.keyboardHelpers !== false && (
                  <>
                    <IconButton
                      onClick={() => {
                        setSearch(search + "і");

                        if (inputRef?.current?.focus) {
                          inputRef?.current?.focus();
                        }
                      }}
                    >
                      <Typography sx={{ width: 24 }}>І</Typography>
                    </IconButton>
                    <IconButton
                      onClick={() => {
                        setSearch(search + "ў");
                        if (inputRef?.current?.focus) {
                          inputRef?.current?.focus();
                        }
                      }}
                    >
                      <Typography sx={{ width: 24 }}>Ў</Typography>
                    </IconButton>
                  </>
                )}

                {!!search && (
                  <IconButton
                    onClick={() => {
                      setSearch("");
                      setCurrentGlossary(false);
                      setResult(null);
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                )}
              </InputAdornment>
            ),
          }}
        />
        {currentGlossary ? (
          <Box sx={{ mt: 2, width: "100%", mb: 2 }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "flex-start",
                pb: 2,
                width: "100%",
              }}
            >
              <Button
                startIcon={<ArrowBackIcon />}
                onClick={() => setCurrentGlossary(null)}
              >
                Назад
              </Button>
            </Box>
            <UseageList
              currentGlossary={currentGlossary}
              theme={theme}
              search={search}
            />
          </Box>
        ) : (
          <>
            {isLoading && (
              <Box
                sx={{
                  justifyContent: "center",
                  alignItems: "center",
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                  height: "100%",
                }}
              >
                <CircularProgress />
              </Box>
            )}
            {!isLoading && !!search && result && !result?.length && (
              <Box
                sx={{
                  justifyContent: "center",
                  alignItems: "center",
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                  height: "100%",
                }}
              >
                <SentimentDissatisfiedIcon sx={{ fontSize: 64, mb: 1 }} />
                <Typography variant="h6">Нічога не знойдзена</Typography>
              </Box>
            )}
            <Box
              sx={{
                justifyContent: "flex-start",
                alignItems: "flex-start",
                display: "flex",
                flexDirection: "column",
                width: "100%",
                mt: 1,
                mb: 2,
              }}
            >
              {!isLoading && search && (
                <GlossariesList
                  glossaries={result}
                  setCurrentGlossary={setCurrentGlossary}
                />
              )}
            </Box>
          </>
        )}
      </Box>
    </Container>
  );
}
