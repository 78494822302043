import * as React from "react";
import ReactDOM from "react-dom";

import { ThemeProvider, createTheme } from "@mui/material/styles";
import qs from "qs";
import { darkTheme, lightTheme } from "./theme";
import * as ServiceWorker from "./serviceWorker";
import App from "./Application";
import CssBaseline from "@mui/material/CssBaseline";
import { ColorModeContext } from "./context/colorMode";
import { FiltersContext } from "./context/filters";
import { SettingsContext } from "./context/settings";
import Privacy from "./Privacy";
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";

const defaultFilters = {
  "anibel.net": true,
};

const defaultSettings = {
  keyboardHelpers: true,
  autoSearch: true,
  language: "be",
};

function Application() {
  const location = useLocation();
  const params = qs.parse(location.search.substring(1));

  const [mode, setMode] = React.useState(
    params?.mode || localStorage.getItem("mode") || "light"
  );
  const [settings, setSettings] = React.useState(
    JSON.parse(
      localStorage.getItem("settings") || JSON.stringify(defaultSettings)
    )
  );

  const [filters, setFilters] = React.useState(
    JSON.parse(
      localStorage.getItem("filters") || JSON.stringify(defaultFilters)
    )
  );

  const colorMode = React.useMemo(
    () => ({
      mode,
      toggleColorMode: () => {
        setMode((prevMode) => {
          const target = prevMode === "light" ? "dark" : "light";
          localStorage.setItem("mode", target);

          return target;
        });
      },
    }),
    [mode]
  );

  const theme = React.useMemo(
    () => createTheme(mode === "light" ? lightTheme : darkTheme),
    [mode]
  );

  React.useEffect(() => {
    const splash = document.getElementById("splash");
    if (splash) {
      splash.classList.add("hidden");
      setTimeout(() => {
        splash.remove();
      }, 1000);
    }
  }, []);

  return (
    <SettingsContext.Provider
      value={{
        ...settings,
        change: (nextState) => {
          setSettings(nextState);
        },
      }}
    >
      <FiltersContext.Provider
        value={{
          ...filters,
          change: (nextState) => {
            setFilters(nextState);
          },
        }}
      >
        <ColorModeContext.Provider value={colorMode}>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <Routes>
              <Route path="/" element={<App />} />
              <Route path="privacy" element={<Privacy />} />
            </Routes>
          </ThemeProvider>
        </ColorModeContext.Provider>
      </FiltersContext.Provider>
    </SettingsContext.Provider>
  );
}

ReactDOM.render(
  <BrowserRouter>
    <Application />
  </BrowserRouter>,
  document.querySelector("#root")
);

ServiceWorker.register();
