import React from "react";
import { Chip, List, ListItem, ListItemText, Typography } from "@mui/material";
import { useFilters } from "./context/filters";

export const Types = {
  "anibel.net": "anibel.net",
};

const GlossariesList = ({ glossaries, setCurrentGlossary }) => {
  const filters = useFilters();

  return (
    <List sx={{ width: "100%" }} dence>
      {glossaries
        ?.filter(({ type }) => {
          if (filters[type] === false) {
            return false;
          }

          return true;
        })
        ?.map((d, i) => {
          const { origin, total, docs } = d;
          return (
            <ListItem
              key={i.toString() + origin}
              button
              onClick={() => setCurrentGlossary(d)}
              sx={{
                borderRadius: 1,
                alignItems: "center",
                paddingTop: "6px!important",
              }}
            >
              <ListItemText
                primary={
                  <>
                    {origin}
                    {!!total && origin !== "bnkorpus.info" && (
                      <Chip
                        label={`${total} супадзенняў`}
                        size="small"
                        sx={{ ml: 1 }}
                        color="primary"
                      />
                    )}
                  </>
                }
                secondary={
                  docs?.length ? (
                    docs
                      .slice(0, origin === "bnkorpus.info" ? 1 : 5)
                      .map(({ content }) => (
                        <Typography fontSize={"inherit"}>
                          <div
                            dangerouslySetInnerHTML={{ __html: content }}
                            id="content"
                          />
                        </Typography>
                      ))
                  ) : (
                    <Typography fontSize={"inherit"}>
                      Няма супадзенняў у гэтым гласарыі
                    </Typography>
                  )
                }
                primaryTypographyProps={{
                  variant: "h5",
                }}
                secondaryTypographyProps={{
                  variant: "caption",
                  sx: {
                    textTransform: "uppercase",
                    mt: 1,
                  },
                }}
              />
            </ListItem>
          );
        })}
    </List>
  );
};

export default GlossariesList;
