import React from "react";

import {
  Divider,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Switch,
} from "@mui/material";
import GrainIcon from "@mui/icons-material/Grain";
import { useThemeMode } from "./context/colorMode";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import { isDesktop } from "react-device-detect";
import InstallDesktopIcon from "@mui/icons-material/InstallDesktop";
import InstallMobileIcon from "@mui/icons-material/InstallMobile";
import { CoffeeRounded } from "@mui/icons-material";
import Settings from "./Settings";
import About from "./About";
import Filters from "./Filters";

const apps = [
  {
    name: "Слоўнікі",
    icon: "https://cdn.vivy.app/logos/dictionaries.png",
    href: "https://play.google.com/store/apps/details?id=app.vivy.dictionaries",
  },
  {
    name: "Гласарыі",
    icon: "https://cdn.vivy.app/logos/glossary.png",
    href: "https://sub.vivy.sub",
  },
  // {
  //   name: "Ўмова",
  //   icon: "https://cdn.vivy.app/logos/umova.png",
  //   href: "https://play.google.com/store/apps/details?id=app.vivy.umova",
  // },
  {
    name: "VivySub",
    icon: "https://cdn.vivy.app/logos/vivysub.png",
    href: "https://sub.vivy.sub",
  },
];

let deferredPrompt;
window.addEventListener("beforeinstallprompt", (e) => {
  e.preventDefault();

  deferredPrompt = e;
});

export const AppMenu = () => {
  const { mode, toggleColorMode } = useThemeMode();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [open, setOpen] = React.useState(Boolean(anchorEl));

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <IconButton onClick={handleClick}>
        <GrainIcon />
      </IconButton>{" "}
      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
      >
        <Link
          href="https://vivy.app"
          target="_blank"
          variant="caption"
          underline="hover"
          sx={{ ml: 2 }}
          onClick={handleClose}
        >
          Vivy Apps
        </Link>
        <List
          dence
          disablePadding
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
            alignItems: "center",
            my: 1,
            mx: 2,
            mb: 1.5,
            maxWidth: 245,
          }}
        >
          {apps.map(({ icon, name, href }) => {
            return (
              <ListItem
                key={name}
                button
                onClick={() => {
                  window.open(href, "_blank");
                  handleClose();
                }}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: "center",
                  padding: 1,
                  borderRadius: 1,
                  minWidth: 80,
                  maxWidth: 80,
                  width: 80,
                }}
              >
                <ListItemIcon sx={{ minWidth: "unset", minHeight: 40 }}>
                  <img
                    src={icon}
                    width="40px"
                    alt={name}
                    style={{ borderRadius: 50 }}
                  />
                </ListItemIcon>
                <ListItemText sx={{ m: 0, mt: 1 }}>{name}</ListItemText>
              </ListItem>
            );
          })}
        </List>

        {!window.matchMedia("(display-mode: standalone)").matches &&
          !localStorage.getItem("installed") &&
          deferredPrompt && (
            <>
              <Divider sx={{ my: 1 }} />
              <MenuItem
                dence
                button
                onClick={() => {
                  deferredPrompt.prompt();
                }}
              >
                <ListItemIcon>
                  {isDesktop ? <InstallDesktopIcon /> : <InstallMobileIcon />}
                </ListItemIcon>
                <ListItemText>Усталяваць праграму</ListItemText>
              </MenuItem>
            </>
          )}

        <Divider sx={{ my: 1 }} />
        <MenuItem onClick={toggleColorMode} dence>
          <ListItemIcon>
            <DarkModeOutlinedIcon />
          </ListItemIcon>
          <ListItemText>Начная тэма</ListItemText>
          <Switch checked={mode === "dark"} edge="end" sx={{ ml: 1 }} />
        </MenuItem>
        <Divider sx={{ my: 1 }} />
        <Filters />
        <Settings />
        <About />
        <Divider sx={{ my: 1 }} />
         <Link href="https://boosty.to/vivy" target="_blank" underline="none">
          <MenuItem dence>
            <ListItemIcon color="primary">
              <CoffeeRounded color="primary" />
            </ListItemIcon>
            <ListItemText>Падтрымаць</ListItemText>
          </MenuItem>
        </Link>
      </Menu>
    </>
  );
};
