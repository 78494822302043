import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Switch,
} from "@mui/material";
import { Book, FilterAltOutlined } from "@mui/icons-material";
import { Types } from "./GlossariesList";
import { useFilters } from "./context/filters";

const defaultFilters = {
  "anibel.net": true,
};

function Filters({ closeOnOpen }) {
  const defaultState = JSON.parse(
    localStorage.getItem("filters") || JSON.stringify(defaultFilters)
  );

  const { change: setState, ...state } = useFilters();

  const handleChange = (key) => (_, value) => {
    const nextState = {
      ...state,
      [key]: value,
    };

    setState(nextState);
  };

  const isChanged = JSON.stringify(state) !== JSON.stringify(defaultState);
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);

    if (closeOnOpen) {
      closeOnOpen();
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <MenuItem onClick={handleClickOpen} dence>
        <ListItemIcon>
          <FilterAltOutlined />
        </ListItemIcon>
        <ListItemText>Фільтр гласарыяў</ListItemText>
      </MenuItem>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Фільтр гласарыяў</DialogTitle>
        <DialogContent>
          <List sx={{ m: -1 }}>
            {Object.keys(Types).map((key) => {
              return (
                <ListItem key={key}>
                  <ListItemIcon sx={{ minWidth: 46 }}>
                    <Book />
                  </ListItemIcon>
                  <ListItemText primary={Types[key].toUpperCase()} />
                  <Switch
                    checked={state[key]}
                    edge="end"
                    sx={{ ml: 1 }}
                    onChange={handleChange(key)}
                  />
                </ListItem>
              );
            })}
          </List>
        </DialogContent>
        <DialogActions sx={{ p: 2 }}>
          <Button
            onClick={() => {
              setTimeout(() => setState(defaultState), 250);
              handleClose();
            }}
          >
            Закрыць
          </Button>
          <Button
            onClick={() => {
              localStorage.setItem("filters", JSON.stringify(state));
              handleClose();
            }}
            autoFocus
            variant="contained"
            disabled={!isChanged}
          >
            Захаваць
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default Filters;
